(() => {
  const handleHeaderMenu = () => {
    let $bodyEl: HTMLBodyElement,
      $headerEl: HTMLElement,
      prevScrollPos = window.scrollY,
      $headerDropdownLinks: NodeListOf<HTMLLIElement>,
      $headerTopBar: HTMLElement,
      $headerNavCtaContainer: HTMLElement,
      headerHeight = 0,
      $root = document.documentElement,
      headerPageBannerHeight: number,
      $pageBanner: HTMLElement,
      winWidth = window.innerWidth;

    // set header height and other css variables related to the elements inside the header
    const updateHeaderCSSVariables = () => {
      const headerTopBarHeight = $headerTopBar?.scrollHeight || 0;
      const headerFindAProviderCTAHeight =
        $headerNavCtaContainer?.scrollHeight || 0;
      headerPageBannerHeight = $pageBanner?.classList.contains(
        'page-banner--show'
      )
        ? $pageBanner?.scrollHeight
        : 0;
      headerHeight = $headerEl?.offsetHeight || 0;

      headerHeight += headerPageBannerHeight;
      $root.style.setProperty('--headerHeight', `${headerHeight}px`);
      $root.style.setProperty(
        '--headerFindAProviderCTAHeight',
        `${
          $headerNavCtaContainer
            ? Math.max(headerFindAProviderCTAHeight, 75)
            : 0
        }px`
      ); // 75px is the minimum height needed
      $root.style.setProperty(
        '--headerTopBarHeight',
        `${headerTopBarHeight}px`
      );
      $root.style.setProperty(
        '--headerPageBannerHeight',
        `${headerPageBannerHeight}px`
      );
      handleWindowScroll();
    };

    // handles all the window resize events
    const handleWindowResize = () => {
      const curWidth = window.innerWidth;
      if (winWidth !== curWidth) {
        winWidth = curWidth;
        updateHeaderCSSVariables();
      }
    };

    // scroll event
    const handleWindowScroll = () => {
      const curScrollPos = window.scrollY;

      // when scroll position is greater than header height, make the header hidden
      // CSS for these classes will take care of the visibility of the header and its elements
      $bodyEl?.classList.toggle(
        'u-page--scrolled',
        curScrollPos > headerHeight
      );

      // when page is scrolled towards the top by 40px, show the header, without the black top bar
      // CSS for these classes will take care of the visibility of the header and its elements
      $bodyEl?.classList.toggle(
        'u-page--scrolled-down',
        curScrollPos > prevScrollPos && curScrollPos - headerHeight > 10
      );

      if (prevScrollPos - curScrollPos > 40) {
        $bodyEl?.classList.add('u-page--scrolled-up-40');
      }

      // when scroll position is lesser than the header height, show the header
      // CSS for these classes will take care of the visibility of the header and its elements
      if (scrollY < headerHeight) {
        $bodyEl?.classList.remove(
          'u-page--scrolled-up-40',
          'u-page--scrolled-down',
          'u-page--scrolled-up'
        );
      }

      prevScrollPos = curScrollPos;
    };

    // initiating the variables used across this file
    const initVariables = () => {
      $headerEl = document.querySelector('header.header') as HTMLElement;
      $bodyEl = document.body as HTMLBodyElement;
      $headerDropdownLinks = $headerEl?.querySelectorAll(
        'li.emu-navigation__item-parent'
      ) as NodeListOf<HTMLLIElement>;
      $headerTopBar = $headerEl?.querySelector(
        '.header__top-bar'
      ) as HTMLElement;
      $headerNavCtaContainer = $headerEl
        .querySelector('.header__nav-cta-container')
        ?.closest('.container') as HTMLElement;
      prevScrollPos = window.scrollY;

      const $mainPageBanner = document.querySelector(
        '.page-banner'
      ) as HTMLElement;
      // only consider page banner if it is above the header, as the calculations for header will not be effected by page banner present anywhere else on the page
      if ($mainPageBanner) {
        const $closestContainer = $mainPageBanner.closest(
          '.container'
        ) as HTMLElement;
        const $nextSibling =
          $closestContainer?.nextElementSibling as HTMLElement;
        if (
          $nextSibling?.classList.contains('experiencefragment') &&
          $nextSibling?.nodeName === 'HEADER'
        ) {
          $pageBanner = document.querySelector('.page-banner') as HTMLElement;
        }
      }
    };

    const appendEvents = () => {
      window.addEventListener('resize', handleWindowResize);

      const throttledFn = window.AAAEM?.utilities?.throttle?.(
        handleWindowScroll,
        50
      );
      if (throttledFn) {
        window.addEventListener('scroll', throttledFn);
      }

      // when menu button is clicked, toggle a class to the body element
      //@ts-ignore
      window.Bus.on('emu-button:click', ({ id, toggleOn }) => {
        if (id === 'header-menu-open-cta') {
          // when mobile menu trigger is clicked, add/remove a class to the body
          // Using this class the menu will be transitioned from left to right
          $bodyEl.classList.toggle('u-page--mobile-menu-open', toggleOn);
          const scrollChange = Math.max(
            0,
            headerPageBannerHeight - prevScrollPos
          );
          $root.style.setProperty(
            '--banner-scrolled-diff',
            scrollChange + 'px'
          );
        }
      });

      // when a parent list item is clicked, adding/removing a class to show the sub nav
      $headerDropdownLinks.forEach(el => {
        const parentAnchorElement = el.querySelector('a');
        parentAnchorElement?.addEventListener('click', e => {
          e.preventDefault();
          el.classList.toggle('show-sub-nav');
        });
      });

      // adding a bus event to listen and trigger css variable changes when called
      window.Bus.on('calcHeaderVars', () => {
        updateHeaderCSSVariables();
      });
    };

    const init = () => {
      updateHeaderCSSVariables();
    };

    // function calls
    initVariables();
    appendEvents();
    init();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleHeaderMenu);
  } else {
    handleHeaderMenu();
  }
})();
