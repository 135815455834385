(() => {
  // If page url has # in it and if tab component is present on page then redirect to tab number present in url
  const handleTabRedirect = () => {
    const pageUrl = window.location.href;
    const isHashPresent = pageUrl.includes('#');

    if (isHashPresent) {
      let hashIndex = pageUrl.indexOf('#');
      const tabUrl = pageUrl.substring(hashIndex + 1);
      const tabId = tabUrl?.replace(/-tab-\d+/, '');
      const tabComponent = document.getElementById(tabId);

      if (tabComponent) {
        const isTabNumber = pageUrl.match(/-(\d+)$/);

        if (isTabNumber && isTabNumber[1]) {
          const tabNumber = isTabNumber[1];
          const selectedTab = tabComponent.querySelector(
            `.aaaem-tabs__tablist li:nth-child(${tabNumber}) a`
          ) as HTMLAnchorElement;

          selectedTab?.click();
          tabComponent?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  const handleCarouselInTab = () => {
    window.Bus.on('emu-tabs:tabClick', tabObj => {
      const tabId = tabObj?.itemClicked?.id;
      const activeTab = document?.querySelector(
        `.aaaem-tabs__tabpanel[aria-labelledby="${tabId}"]`
      );
      const carousels = activeTab?.querySelectorAll('.emu-carousel');

      if (carousels?.length) {
        // carousel has resize events that adjusts the width of the carousel
        // triggering the events to auto adjust the carousel widths
        window.dispatchEvent(new Event('resize'));
      }
    });
  };

  const init = () => {
    handleTabRedirect();
    handleCarouselInTab();
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
