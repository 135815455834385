(() => {
  // on resize and on expand of accordion, making sure that the height of the accordion content is accurate
  const handleAccordionHeight = () => {
    const accordion = document.querySelector('.aaaem-accordion');
    if (accordion) {
      let winWidth = window.innerWidth;
      const badgerInstances = window._badgerInstances;
      const badgerKeys = badgerInstances && Object.keys(badgerInstances);
      if (badgerKeys?.length) {
        const resizeBadgerHeights = () => {
          badgerKeys.forEach(key => {
            window._badgerInstances[key].calculateAllPanelsHeight?.();
          });
        };

        // making sure the height of the accordion content is accurate on load.
        window.addEventListener('load', () => {
          resizeBadgerHeights();
        });

        // making sure the height of the accordion content is accurate on resize.
        window.addEventListener('resize', () => {
          const curWidth = window.innerWidth;
          if (winWidth !== curWidth) {
            winWidth = curWidth;
            resizeBadgerHeights();
          }
        });
      }
    }

    window.Bus.on('emu-accordion:toggle', ({ id, itemClicked }) => {
      const badgerInstance = window._badgerInstances[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (accordionContent) {
          badgerInstance.calculatePanelHeight(accordionContent);
        }
      }
    });
  };

  const manageExpandAllCTA = () => {
    const $accordions = document.querySelectorAll('.aaaem-accordion');
    const handleExpandAll = ($cta, $acc) => {
      window.Bus.on('emu-accordion:toggle', data => {
        if (data.id === $acc.id) {
          const openedItems = data.items.filter(item => item.opened);
          // if everything is opened, and if the toggle all button is not toggled(does not show collapse all text), click toggle all with JS
          // if everything is closed, and if the toggle all button is toggled(does not show expand all text), click toggle all with JS
          if (
            (openedItems.length === data.items.length &&
              !$cta.classList.contains('js-toggle-on')) ||
            (openedItems.length === 0 &&
              $cta.classList.contains('js-toggle-on'))
          ) {
            $cta.click();
          }
        }
      });
    };

    $accordions.forEach($acc => {
      const id = $acc.id;
      const $targetCTA = document.querySelector(`[data-component-id="${id}"]`);

      if ($targetCTA) {
        handleExpandAll($targetCTA, $acc);
      }
    });
  };

  const init = () => {
    handleAccordionHeight();
    manageExpandAllCTA();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
