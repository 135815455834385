(() => {
  // This function will handle card expansion on clicking read more button
  const handleReadMore = () => {
    let $readMoreCTAs,
      $brandCards,
      winWidth = window.innerWidth;

    const initVariables = () => {
      $readMoreCTAs = document.querySelectorAll(
        '.brand-card__cta--expand-text-trigger'
      ) as NodeListOf<HTMLElement>;
      $brandCards = document.querySelectorAll(
        '.brand-card'
      ) as NodeListOf<HTMLElement>;
    };

    const appendEvents = () => {
      // resize event to remove minHeight from the Brand Cards
      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          $brandCards.forEach($el => {
            $el.style.minHeight = '';
          });
        }
      });

      // click event on brand card CTAs
      if ($readMoreCTAs?.length) {
        $readMoreCTAs?.forEach($el => {
          const $brandCard = $el.closest(
            '.brand-card--expandable'
          ) as HTMLElement;
          const $brandCardText = $brandCard?.querySelector(
            '.brand-card__txt'
          ) as HTMLElement;
          if ($brandCard && $brandCardText) {
            const $brandCardImg =
              $brandCard.querySelector('.brand-card__asset');
            $el.addEventListener('click', () => {
              const shouldExpand = $el.classList.contains('js-toggle-on');
              if (shouldExpand) {
                $brandCard.style.minHeight = $brandCard.clientHeight + 'px';
                if ($brandCardImg) {
                  $brandCardImg?.addEventListener(
                    'transitionend',
                    () => {
                      $brandCardText.style.display = 'block';
                      $brandCardText.classList.add('brand-card__text--expand');
                    },
                    {
                      once: true,
                    }
                  );
                } else {
                  $brandCardText.style.display = 'block';
                  $brandCardText.classList.add('brand-card__text--expand');
                }
                $brandCard?.classList.add('brand-card--expandable-inflate');
              } else {
                $brandCardText.classList.remove('brand-card__text--expand');
                $brandCard?.classList.remove('brand-card--expandable-inflate');
                $brandCardText.style.display = '-webkit-box';
                if ($brandCardImg) {
                  $brandCardImg?.addEventListener(
                    'transitionend',
                    () => {
                      $brandCard.style.minHeight = '';
                    },
                    {
                      once: true,
                    }
                  );
                } else {
                  $brandCard.style.minHeight = '';
                }
              }
            });
          }
        });
      }
    };

    initVariables();
    appendEvents();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleReadMore);
  } else {
    handleReadMore();
  }
})();
