import './alle-redirect';

(() => {
  const init = () => {
    const supportsHasPseudoClass = () => {
      try {
        document.querySelector(':has(*)');
        return true;
      } catch (e) {
        return false;
      }
    };

    const handleHasSupports = () => {
      const mapper = [
        'u-mx-auto',
        'u-right-align',
        'u-center-align',
        'brand-card__content',
        'page-banner',
        'brand-card',
        'brand-card__disc',
        'u-mt-auto',
      ];

      for (const key of mapper) {
        const $els = document.querySelectorAll(`.${key}`);
        $els.forEach($el => {
          const $parent = $el.parentElement as HTMLElement;
          $parent?.classList.add(`js-has-${key}`);
        });
      }
    };

    //to check dvh
    function checkDvhSupport() {
      // Check if the browser supports dvh
      if (!CSS.supports('height', '100dvh')) {
        // If dvh is not supported, add the 'not-supports-dvh' class
        document.body.classList.add('not-supports-dvh');
      }
    }

    // this function will handle all anchors with href as #isi
  const handleIsiClick = () => {
    document.querySelectorAll('a[href="#isi"]').forEach(anchor => {
      anchor.addEventListener('click', function (event) {
        event.preventDefault();

        const isiButton = document.querySelector(
          '.aaaem-isi-container__button-wrapper button'
        ) as HTMLElement;
        if (isiButton) {
          isiButton.click();
        }
      });
    });
  };

    // function calls
    checkDvhSupport();
    handleIsiClick();

    // when supports pseudo selector is not supported
    // add corresponding classes to the parent elements to add appropriate styles
    if (supportsHasPseudoClass() !== true) {
      handleHasSupports();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
