(() => {
  // open modal on click of image map button
  const handleImageMap = () => {
    window.Bus.on('emu-image-map:areaClick', imageMapObj => {
      if (imageMapObj.isActive) {
        // click image map button to change its isActive state
        const $imageMapBtn = document.getElementById(imageMapObj.id);
        const $interactiveMap = $imageMapBtn?.closest('.interactive-map');

        if ($interactiveMap) {
          // code to trigger modal on click of image map button
          const triggerBtnId = imageMapObj.title + '-modal-trigger';
          const $triggerBtn = document.getElementById(triggerBtnId);
          $triggerBtn?.click?.();

          // click image map button to change its isActive state
          $imageMapBtn?.click?.();
        }
      }
    });
  };

  const init = () => {
    handleImageMap();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
