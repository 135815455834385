(() => {
  const handlePageBanner = ($banner: HTMLElement) => {
    let $pageBannerClose: NodeListOf<HTMLElement>,
      pageBannerId: string,
      isHeaderPageBanner = false;

    const checkIfItIsHeaderPageBanner = () => {
      const $closestContainer = $banner.closest('.container') as HTMLElement;
      const $nextSibling = $closestContainer?.nextElementSibling as HTMLElement;
      isHeaderPageBanner =
        $nextSibling?.classList.contains('experiencefragment') &&
        $nextSibling?.nodeName === 'HEADER';
    };

    // before showing the banner, make sure that its ID is not in localstorage
    // once decided to show, adds a class and add a max height to show the content
    const showBanner = () => {
      if (pageBannerId && $pageBannerClose.length) {
        if (localStorage.getItem(pageBannerId) !== 'closed') {
          $banner.classList.add('page-banner--show');
          $banner.style.maxHeight = $banner.scrollHeight + 'px';
        }
      } else {
        $banner.classList.add('page-banner--show');
        $banner.style.maxHeight = $banner.scrollHeight + 'px';
      }
    };

    const closeBanner = () => {
      $banner.classList.remove('page-banner--show');
      $banner.style.maxHeight = '';
      window.Bus.emit('calcHeaderVars');
      if (pageBannerId && $pageBannerClose.length) {
        localStorage.setItem(pageBannerId, 'closed');
      }
    };

    const initVariables = () => {
      $pageBannerClose = $banner.querySelectorAll(
        '.page-banner__cta--as-close'
      );
      pageBannerId = $banner.id;
      checkIfItIsHeaderPageBanner();
    };

    const appendEvents = () => {
      $pageBannerClose.forEach($el => {
        $el.addEventListener('click', () => {
          closeBanner();
        });
      });

      // making sure that the header related variables are all updated if it is a page banner present above the header
      if (isHeaderPageBanner) {
        $banner.addEventListener('transitionend', () => {
          window.Bus.emit('calcHeaderVars');
        });
      }
    };

    initVariables();
    appendEvents();
    showBanner();
  };

  const init = () => {
    const $pageBanners = document.querySelectorAll(
      '.page-banner'
    ) as NodeListOf<HTMLElement>;
    $pageBanners.forEach($el => {
      handlePageBanner($el);
    });

    // when resized, resetting the banner max heights
    window.addEventListener('resize', () => {
      $pageBanners.forEach($el => {
        if ($el.classList.contains('page-banner--show')) {
          $el.style.maxHeight = $el.scrollHeight + 'px';
        }
      });
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
